import durationIcon from '../../../assets/icons/movie-card/iconoir/duration.svg'
import languageIcon from '../../../assets/icons/movie-card/iconoir/language.svg'
import resolutionIcon from '../../../assets/icons/movie-card/iconoir/resolution.svg'
import qualityIcon from '../../../assets/icons/movie-card/iconoir/quality.svg'
import seasonepisodeIcon from '../../../assets/icons/movie-card/iconoir/seasonepisode.svg'
import sizeIcon from '../../../assets/icons/movie-card/iconoir/size.svg'
import yearIcon from '../../../assets/icons/movie-card/iconoir/year.svg'

import { MetaData } from './MetaData'

export const MetaDatas = ({ file, color }) => {
  return (
    <>
      {file && (
        <>
          {file.seasonepisode && <MetaData icon={seasonepisodeIcon} text={file.seasonepisode} color={color} />}
          {file.year && <MetaData icon={yearIcon} text={file.year} color={color} />}
          {file.language && <MetaData icon={languageIcon} text={file.language} color={color} />}
          {file.resolution && <MetaData icon={resolutionIcon} text={file.resolution} color={color} />}
          {file.quality && <MetaData icon={qualityIcon} text={file.quality} color={color} />}
          {!file.isDir && file.duration && <MetaData icon={durationIcon} text={file.duration} color={color} />}
          {!file.isDir && file.size && <MetaData icon={sizeIcon} text={file.size} color={color} />}
        </>
      )}
    </>
  )
}
