import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import playIcon from '../../assets/icons/file-list/play.svg'
import colors from '../../utils/colors'
import { fileIcons, mediaExtensions } from '../../utils/utilsDatas'

const getExtension = (filename) => {
  const matcher = filename.match(/.*(\.[a-zA-Z0-9]*)$/i)
  const ext = matcher ? matcher[1] : null
  return ext
  // console.log(ext)
}

const isMedia = (filename) => {
  const ext = getExtension(filename)
  if (ext && mediaExtensions.includes(ext)) {
    return true
  }
  return false
}

const TableContainer = styled.div`
  margin-top: 5px;
  padding-top: 5px;
  overflow-y: auto;
  max-height: calc(100vh - 104px);

  @media only screen and (min-width: 768px) {
    width: 80%;
    min-width: 330px;
  }

  ::-webkit-scrollbar {
    /* display: none; */
  }

  ::-webkit-scrollbar-track-piece:start {
    /* margin-top: 30px; */
  }
  ::-webkit-scrollbar-track-piece:end {
    /* margin-bottom: 30px; */
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: 10px;
  }

  ::-webkit-scrollbar-track {
    /* background: transparent; */
    scrollbar-width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: ${colors.light_main_gradient1}; */
    border-radius: 25px;
    box-shadow: inset 0 0 10px 10px ${colors.light_main_gradient1};
    border: solid 2px transparent;
  }
`
const StyledCaption = styled.caption`
  margin-bottom: 1em;
`

const StyledTable = styled.table`
  /* border: 1px solid; */
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
`
const StyledThead = styled.thead`
  width: 100%;
  color: white;
  background: ${({ theme }) =>
    theme.isDarkMode
      ? `${colors.dark_main}`
      : `linear-gradient(60deg, ${colors.light_main_gradient1} 0%, ${colors.light_main_gradient2} 50%, ${colors.light_main_gradient3} 100%)`};
`
const StyledTbody = styled.tbody`
  background-color: ${({ theme }) =>
    theme.isDarkMode ? `${colors.dark_main_gradient1}b0` : `${colors.light_main_gradient3}b0`};
  /* backdrop-filter: blur(10px); */
  width: 100%;
`

const RowLink = styled(Link)`
  /* display: flex; */
  text-decoration: none;
  color: unset;
`

const StyledTr = styled.tr`
  vertical-align: center;
  width: 100%;
`

const StyledTh = styled.th`
  /* border: 1px solid; */
  padding: 0.8em;
  border-bottom: 1px solid white;
`
const NameTh = styled(StyledTh)`
  border-radius: 25px 0 0 0;
  width: 60%;
`
const SizeTh = styled(StyledTh)`
  text-align: right;
  padding-right: 0.5em;
  width: 20%;
`
const PlayTh = styled(StyledTh)`
  border-radius: 0 25px 0 0;
  width: 10%;
`

const StyledTd = styled.td`
  /* border: 1px solid; */
  border-bottom: 1px solid white;
  padding: 0.5em;
`
const FileNametd = styled(StyledTd)`
  line-break: anywhere;
  /* display: flex;
  flex-wrap: nowrap;
  align-items: center; */
`

const SizeTd = styled(StyledTd)`
  text-align: right;
  /* padding-right: 0.5em; */
`
const PlayTd = styled(StyledTd)`
  text-align: center;
`

const DownloadLink = styled.a`
  color: unset;
  text-decoration: none;
`

const CardLinkWrapper = ({ condition, folderWrap, fileWrap, children }) =>
  condition ? folderWrap(children) : fileWrap(children)

export const TableList = ({ fileList }) => {
  const currentPath = useLocation().pathname

  return (
    <TableContainer>
      <StyledTable>
        <StyledCaption>Contenu du dossier {fileList[0]?.baseDir}</StyledCaption>
        <StyledThead>
          <StyledTr>
            <NameTh>Nom</NameTh>
            <SizeTh>Taille</SizeTh>
            <PlayTh></PlayTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>
          <StyledTr>
            <FileNametd>
              <FileExtensionIcon isDir={true} filename={'back'} />
              <RowLink to={`./../`}>retour..</RowLink>
            </FileNametd>
            <SizeTd></SizeTd>
            <PlayTd></PlayTd>
          </StyledTr>
          {fileList.map((file) => {
            return (
              <StyledTr key={file.filename}>
                <FileNametd>
                  <FileExtensionIcon isDir={file.isDir} filename={file.filename} />
                  <CardLinkWrapper
                    condition={file.isDir}
                    folderWrap={(children) => <RowLink to={`${file.filename}/`}>{children}</RowLink>}
                    fileWrap={(children) => (
                      <DownloadLink href={file.directLink} download>
                        {children}
                      </DownloadLink>
                    )}
                  >
                    {file.filename}
                  </CardLinkWrapper>
                </FileNametd>
                <SizeTd>{file.size}</SizeTd>
                <PlayTd>
                  {isMedia(file.filename) && (
                    <RowLink to={`/play${currentPath}${file.filename}`}>
                      <PlayIconAction src={playIcon} alt="icone lecture" />
                    </RowLink>
                  )}
                </PlayTd>
              </StyledTr>
            )
          })}
        </StyledTbody>
      </StyledTable>
    </TableContainer>
  )
}

const PlayIconAction = styled.img`
  display: inline-block;
  width: 24px;
  vertical-align: top;

  ${({ theme }) =>
    theme.isDarkMode
      ? `filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg)
    brightness(102%) contrast(101%);`
      : null};
`

const FileIcon = styled.img`
  width: 1em;
  margin-right: 1em;
  /* float: left; */
  vertical-align: middle;
  /* ${({ theme }) =>
    theme.isDarkMode
      ? `filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg)
    brightness(102%) contrast(101%);`
      : null}; */
`

function FileExtensionIcon({ isDir, filename }) {
  let icon
  if (isDir) {
    // icon = folderIcon
    icon = filename === 'back' ? fileIcons.back : fileIcons.folder
  } else {
    const ext = getExtension(filename)
    // console.log('filename', filename)
    // console.log('ext', ext)
    icon = fileIcons[ext]
  }
  icon ??= fileIcons.default
  return <FileIcon src={icon} alt="icone de fichier" />
}
