import styled from 'styled-components'
import colors from '../../utils/colors'
import diskIcon from '../../assets/icons/movie-card/iconoir/size.svg'
import { useContext, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'

const StyledDiv = styled.div`
  color: ${colors.white};
  display: flex;
  align-items: flex-end;
  gap: 5px;
`

const Icon = styled.img`
  height: 24px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(102%) contrast(101%);
`

export function SpaceLeft({ size }) {
  const { theme } = useContext(ThemeContext)
  const { authToken, setAuthToken } = useContext(AuthTokenContext)

  const [freespace, setFreespace] = useState()
  const [dataLoading, setDataLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function fetchFreeSpace() {
      let resp
      try {
        setError(false)
        setDataLoading(true)
        resp = await fetch(process.env.REACT_APP_API_URL + '/free-space', {
          headers: new Headers({
            Authorization: `Bearer ${authToken}`,
            Accept: 'application/json',
          }),
        })

        if (!resp.ok) return

        const data = await resp.json()
        // console.log(data)
        setFreespace(data.available)
      } catch (error) {
        console.error(error)
        setError(true)
      } finally {
        setDataLoading(false)
        if (resp.status > 299) setError(true)
        if (resp.status === 401) setAuthToken(null)
        if (resp.ok) setError(false)
      }
    }

    fetchFreeSpace()
  }, [authToken])

  return (
    <StyledDiv title="place disponible sur le disque">
      {!error && (
        <>
          <Icon src={diskIcon} alt="disk icon" />
          {dataLoading && (
            <ReactLoading
              type={'bubbles'}
              color={theme === 'dark' ? `${colors.white}` : `${colors.white}`}
              height={'24px'}
              width={'24px'}
            />
          )}
          {!dataLoading && <div>{freespace}</div>}
        </>
      )}
    </StyledDiv>
  )
}
