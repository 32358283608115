import styled, { css } from 'styled-components'
import colors from '../../utils/colors'
import closeIcon from '../../assets/icons/close.svg'

import downloadIcon from '../../assets/icons/movie-card/iconoir/download.svg'
import playIcon from '../../assets/icons/movie-card/iconoir/play.svg'
import copylinkIcon from '../../assets/icons/movie-card/iconoir/copylink.svg'
import { StarIcon } from './StarIconComponent'
import { ActionIcon } from './ActionIcon'

import CopyToClipboard from 'react-copy-to-clipboard'

import { RatingStar, RatingStarContainer } from 'rating-star'
import { MetaDatas } from './MetaData/MetaDatas'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { useContext } from 'react'
import { toast } from 'react-toastify'

const BlurryBG = styled.div`
  @media only screen and (max-width: 768px) {
    position: fixed;
  }

  width: 100%;
  height: 100%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  background: ${colors.black}bf;
  backdrop-filter: blur(6px);

  z-index: 3;

  display: flex;
`
const InfosPopupRatioWraper = styled.div`
  left: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
  z-index: 4;
  width: 45%;
  padding-top: 28%;
  position: fixed;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 80%;
    padding-top: 50%;
  }

  @media only screen and (min-width: 768px) {
    /* min-width: 550px; */
    /* min-height: 100px; */
  }
`

const InfosPopup = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  overflow: hidden;

  /* width: 550px;
  height: 300px; */
  /* width: 45%; */
  /* height: 35%; */
  margin: auto;
  z-index: 4;

  //fond noir blurry
  ${({ theme }) =>
    theme === 'dark' &&
    css`
      background-color: ${colors.black}75;
      ${({ backgroundCover }) =>
        `background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundCover});`}
      background-size: cover;
      color: ${colors.white};
    `}

  //fond blanc blurry
  ${({ theme }) =>
    theme === 'light' &&
    css`
      background-color: ${colors.black}75;
      ${({ backgroundCover }) =>
        `background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)), url(${backgroundCover});`}
      background-size: cover;
      color: ${colors.black};
    `}

  border-radius: 25px;

  display: flex;
`

const Cover = styled.img`
  /* width: 100%; */
  border-radius: 25px 0 0 25px;
  object-fit: cover;
  aspect-ratio: 2/3;
`

const RightCol = styled.div`
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ backgroundCover }) => backgroundCover && `backdrop-filter: blur(5px);`}

  overflow-x: hidden;

  overflow-y: scroll;
  ::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
  }
  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: 10px;
  }

  ::-webkit-scrollbar-track {
    /* background: transparent; */
    scrollbar-width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: ${colors.light_main_gradient1}; */
    border-radius: 25px;
    box-shadow: inset 0 0 10px 10px ${colors.light_main_gradient1};
    border: solid 3px transparent;
  }
`
const Title = styled.h2`
  color: ${colors.light_main_gradient1};
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0;
`
const Filename = styled.div`
  font-size: 0.8em;
  text-align: left;
`

const Season = styled.div``
const Synopsis = styled.div`
  text-align: left;
  margin: 10px 0px;
`

const RankActionContainer = styled.div`
  padding-top: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Separator = styled.span`
  /* position: absolute; */
  margin: 5px 0px;
  border-bottom: 1px solid ${({ theme }) => (theme === 'dark' ? `white` : `${colors.light_main_gradient1}`)};
  width: 100%;
`

const MetaDataContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
`

const StyledRatingStar = styled.div`
  ${RatingStarContainer} {
    margin: 0px;
    padding: 0px;
  }
`

const Actors = styled.div`
  text-align: left;
  margin-bottom: 15px;
  font-size: 0.75em;
`

const CloseButton = styled.img`
  position: absolute;
  right: 2%;
  top: 2%;

  height: 24px;

  cursor: pointer;

  ${({ theme }) =>
    theme === 'dark'
      ? `filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg)
    brightness(102%) contrast(101%);`
      : null}
`

const ActionMenu = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
`

export function FileInfoPopup({ setVisible, file, poster, backgroundCover }) {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <BlurryBG onClick={() => setVisible(false)}></BlurryBG>
      <InfosPopupRatioWraper>
        <InfosPopup backgroundCover={backgroundCover} theme={theme}>
          <Cover src={poster} />
          <RightCol backgroundCover={backgroundCover}>
            {file.title && <Title> {file.title} </Title>}
            <Filename>{file.filename}</Filename>
            {file.seasonepisode && <Season>{file.seasonepisode}</Season>}
            <RankActionContainer>
              <StyledRatingStar>
                {file.note && (
                  <RatingStar
                    noBorder
                    id={`rating-${file.tmdbID || file.filename.replaceAll(' ', '')}`}
                    starIcon={StarIcon}
                    colors={{ mask: theme === 'dark' ? `${colors.dark_main}` : `${colors.light_main_gradient1}` }}
                    rating={file.note}
                  />
                )}
              </StyledRatingStar>
              <ActionMenu>
                {!file.isDir && (
                  <>
                    <a style={{ display: 'inherit' }} href={file.directLink} download>
                      <ActionIcon
                        icon={downloadIcon}
                        bubbleInfoText="télécharger"
                        color={theme === 'dark' && 'white'}
                      />
                    </a>
                    <ActionIcon
                      icon={playIcon}
                      bubbleInfoText="lire"
                      color={theme === 'dark' && 'white'}
                      link={`/play${file.baseDir + file.filename}`}
                    />
                    <CopyToClipboard text={file.directLink}>
                      <div style={{ display: 'inherit' }}>
                        <ActionIcon
                          icon={copylinkIcon}
                          bubbleInfoText="copier le lien direct"
                          color={theme === 'dark' && 'white'}
                          onClickAction={() => {
                            toast('🔗 Lien direct copié !', {
                              // position: 'top-center',
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              theme: 'light',
                            })
                          }}
                        />
                      </div>
                    </CopyToClipboard>
                  </>
                )}
              </ActionMenu>
            </RankActionContainer>
            <Separator theme={theme}></Separator>
            <MetaDataContainer>
              <MetaDatas file={file} color={theme === 'dark' && 'white'} />
            </MetaDataContainer>
            <Separator theme={theme}></Separator>
            {file.synopsis && <Synopsis>{file.synopsis}</Synopsis>}
            {file.casting && <Actors> Avec : {file.casting.join(', ')}</Actors>}
          </RightCol>
          <CloseButton theme={theme} src={closeIcon} onClick={() => setVisible(false)} />
        </InfosPopup>
      </InfosPopupRatioWraper>
    </>
  )
}
