import { useLocation } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { SearchBar } from '../../components/SearchBar'
import { VideoFileCard } from '../../components/VideoFileCard/VideoFileCard'
import { Error } from '../../components/Errors'

import colors from '../../utils/colors'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'

import ReactLoading from 'react-loading'
import { PopupNotif } from '../../components/PopupNotif/PopupNotif'

import Breadcrumb from '../../components/Breadcrumb'

export const MainContent = styled.div`
  /* width: 85%; */
  /* margin-top: 5px;
  padding-top: 5px; */
  /* margin-top: 5px;
  padding-top: 5px; */
  display: flex;
  margin-right: auto;
  margin-left: auto;

  flex-direction: column;
  align-items: center;
  gap: 1em;

  @media only screen and (max-width: 768px) {
    /* height: 100%; */
    width: 95%;
    height: calc(100% - 85px);
  }
`

export const FilesContainer = styled.div`
  @media only screen and (max-width: 768px) /* For mobiles*/ {
    width: 100%;
  }

  @media only screen and (min-width: 768px) /* For mobiles*/ {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  /* width: 85%;  */
  margin-top: 5px;
  padding-top: 5px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  gap: 30px;
  row-gap: 30px;

  /* background-color: ${colors.white_transparent}; */
  border-radius: 25px;

  overflow-y: auto;
  /* position: absolute; */
  max-height: calc(100vh - 104px);
  /* min-height: calc(100vh - 115px); */
  ::-webkit-scrollbar {
    display: none;
  }
`

export function MediaListingPage({ type }) {
  const { theme } = useContext(ThemeContext)
  const { authToken, setAuthToken } = useContext(AuthTokenContext)

  const currentPath = useLocation().pathname
  const baseDir = currentPath

  const [mediaListLoading, setMediaListLoading] = useState(true)
  const [mediaListError, setMediaListError] = useState(false)
  const [mediaList, setMediaList] = useState()
  const [unfilteredMediaList, setUnfilteredMediaList] = useState()
  const [isExistingDir, setIsExistingDir] = useState(true)
  const [isEmptyDir, setIsEmptyDir] = useState(false)

  useEffect(() => {
    async function fetchMediaList() {
      let resp

      try {
        // console.log('fetching media list...')
        setMediaListLoading(true)
        setMediaListError(false)
        setIsExistingDir(true)
        setIsEmptyDir(false)

        resp = await fetch(process.env.REACT_APP_API_URL + currentPath, {
          headers: new Headers({
            Authorization: `Bearer ${authToken}`,
            Accept: 'application/json',
          }),
        })

        if (!resp.ok) {
          return
        }

        const rawData = await resp.json()
        const data = []
        for (let file of rawData) {
          data.push({ ...file, type, baseDir })
        }
        setMediaList(data)
        setUnfilteredMediaList(data)
        if (data.length < 1) {
          setIsEmptyDir(true)
        }
      } catch (error) {
        setMediaListError(true)
        // console.error(error)
      } finally {
        setMediaListLoading(false)
        if (resp.status === 404) setIsExistingDir(false)
        if (resp.status === 401) setAuthToken(null)
      }
    }

    fetchMediaList()
  }, [useLocation(), authToken])

  return (
    <MainContent>
      <PopupNotif />
      {!isExistingDir && <Error errorCode={404}></Error>}
      {mediaListError && <Error errorCode={500}></Error>}
      {isExistingDir && (
        <>
          {mediaList && (
            <SearchBar
              key={baseDir}
              unfilteredMediaList={unfilteredMediaList && unfilteredMediaList}
              setMediaList={setMediaList}
            />
          )}

          <Breadcrumb />

          <FilesContainer>
            {mediaListLoading ? (
              <ReactLoading
                type={'bubbles'}
                color={theme === 'dark' ? `${colors.dark_main}` : `${colors.light_main_gradient1}`}
                height={'124px'}
                width={'124px'}
              />
            ) : isEmptyDir ? (
              <>Dossier vide</>
            ) : (
              mediaList && mediaList.map((file) => <VideoFileCard key={file.filename} file={file} />)
            )}
          </FilesContainer>
        </>
      )}
    </MainContent>
  )
}
