import PropTypes from 'prop-types'
import { useContext } from 'react'

import styled from 'styled-components'

import { ThemeContext } from '../../utils/useContext/ThemeContext'

import illustrationError500dark from '../../assets/error-500-dark.svg'
import illustrationError500light from '../../assets/error-500-light.svg'
import illustrationError404dark from '../../assets/error-404-dark.svg'
import illustrationError404light from '../../assets/error-404-light.svg'

// https://www.freepik.com/free-vector/500-internal-server-error-concept-illustration_13416109.htm#query=500&position=0&from_view=search&track=sph
// https://storyset.com/illustration/oops-404-error-with-a-broken-robot/cuate#utm_source=freepik&utm_medium=referall&utm_campaign=storiesdetail&utm_content=edit-button&utm_term=edit

const StyledDiv = styled.div`
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 768px) {
    width: 60%;
  }

  text-align: center;
`

const StyledH1 = styled.h1`
  font-size: 1.5em;
`

const Illustration = styled.img`
  width: 70%;
`
export function Error({ errorCode }) {
  const { theme } = useContext(ThemeContext)

  const errorMsg = {
    500: 'Une erreur serveur est survenue, merci de réessayer plus tard.',
    404: 'Le contenu demandé est introuvable.',
  }

  const errorIllustration = {
    500: { dark: illustrationError500dark, light: illustrationError500light },
    404: { dark: illustrationError404dark, light: illustrationError404light },
  }

  return (
    <StyledDiv>
      <StyledH1>{errorMsg[errorCode]}</StyledH1>

      <Illustration
        src={theme === 'dark' ? errorIllustration[errorCode].dark : errorIllustration[errorCode].light}
        alt={`Erreur ${errorCode}`}
      />
    </StyledDiv>
  )
}
