import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'
import { ThemeContext } from '../../utils/useContext/ThemeContext'

import ReactLoading from 'react-loading'
import colors from '../../utils/colors'
import { Error } from '../../components/Errors'
import { useLocation } from 'react-router-dom'
import { MainContent as MediaMainContent } from '../MediaListing'

const MainContent = styled(MediaMainContent)`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
`
const StyledH1 = styled.h1`
  font-size: 1.5em;
`
const SpeedTestResult = styled.div``

const ImageResult = styled.img`
  max-width: 500px;
`

export const Speedtest = () => {
  const { theme } = useContext(ThemeContext)
  const { authToken, setAuthToken } = useContext(AuthTokenContext)

  const [speedtestResult, setSpeedtestResult] = useState()
  const [isLoading, setIsLoading] = useState()
  const [error, setError] = useState()
  const [notFound, setNotFound] = useState()

  useEffect(() => {
    async function fetSpeedTest() {
      let resp

      try {
        setIsLoading(true)
        setError(false)
        setNotFound(false)

        resp = await fetch(process.env.REACT_APP_API_URL + '/speedtest', {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${authToken}`,
            Accept: 'application/json',
          }),
        })

        if (!resp.ok) {
          return
        }

        const data = await resp.json()
        setSpeedtestResult(data.embedLink)
      } catch (error) {
        setError(true)
        console.error(error)
      } finally {
        setIsLoading(false)
        if (resp.status === 404) setNotFound(true)
        if (resp.status === 401) setAuthToken(null)
      }
    }

    fetSpeedTest()
  }, [useLocation(), authToken])

  return (
    <MainContent>
      {isLoading && (
        <ReactLoading
          type={'bubbles'}
          color={theme === 'dark' ? `${colors.dark_main}` : `${colors.light_main_gradient1}`}
          height={'124px'}
          width={'124px'}
        />
      )}

      {notFound && <Error errorCode={404} />}
      {error && <Error errorCode={500} />}

      {speedtestResult && (
        <SpeedTestResult>
          <StyledH1>Speedtest du serveur :</StyledH1>
          <a href={speedtestResult}>
            <ImageResult src={`${speedtestResult}.png`} alt="resultat du speedtest" />
          </a>
        </SpeedTestResult>
      )}
    </MainContent>
  )
}
