import { NavLink, useLocation, matchPath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import colors from '../../utils/colors'
import { Logo } from '../Logo'

import filmIcon from '../../assets/icons/menu/iconoir/film.svg'
import serieIcon from '../../assets/icons/menu/iconoir/device-tv.svg'
import diversIcon from '../../assets/icons/menu/iconoir/box.svg'
import musicIcon from '../../assets/icons/menu/iconoir/music.svg'
import speedtestIcon from '../../assets/icons/menu/iconoir/speedtest.svg'

import ThemeToggle from '../ThemeToogle/ThemeToogle'
import { SpaceLeft } from './SpaceLeft'
import { useEffect, useState } from 'react'

const StyledHeader = styled.header`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.light_main_gradient1}`};

  /* menu moderne */
  ${(props) =>
    !props.theme.isDarkMode &&
    css`
      background: ${colors.light_main_gradient1};
      background: linear-gradient(
        60deg,
        ${colors.light_main_gradient1} 0%,
        ${colors.light_main_gradient2} 50%,
        ${colors.light_main_gradient3} 100%
      );
      box-shadow: 0px 10px 100px 5px ${colors.light_main_gradient2};
    `}
  ${(props) =>
    props.theme.isDarkMode &&
    css`
      background: ${colors.dark_secondary};
      background: linear-gradient(
        60deg,
        ${colors.dark_main_gradient1} 0%,
        ${colors.dark_main_gradient2} 50%,
        ${colors.dark_main_gradient3} 100%
      );
      box-shadow: 0px 10px 100px 5px #2e406c;
    `}
      
  @media only screen and (min-width: 768px) /* For desktops: */ {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;

    /* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#855eeb",endColorstr="#c3a2f2",GradientType=1); */

    border-radius: 25px;

    margin: 20px 0px 20px 20px;
    height: calc(100vh - 40px);
  }
`
const BurgerMenu = styled.span`
  display: block;
  position: fixed;
  z-index: 3;
  /* top: 1rem; */
  left: 1rem;

  :hover {
    cursor: pointer;
  }

  width: 35px;
  height: 4px;
  border-radius: 3px;
  background-color: white;
  transition: all 0.5s ease;
  ${({ showMenu }) =>
    showMenu &&
    css`
      transform: rotate(45deg);
      height: 2px;
    `};

  ::before,
  ::after {
    display: block;
    width: 35px;
    height: 4px;
    border-radius: 3px;
    background-color: white;
    transition: all 0.5s ease;
    content: '';
    position: absolute;
  }

  ::before {
    transform: translateY(-12px);
    ${({ showMenu }) => showMenu && `transform: rotate(0deg);`}
  }

  ::after {
    transform: translateY(12px);
    ${({ showMenu }) => showMenu && `transform: rotate(90deg);`}
  }

  @media only screen and (min-width: 768px) /* For desktops: */ {
    display: none;
  }
`
const MenuContainer = styled.div`
  height: 100%;

  @media only screen and (max-width: 768px) {
    ${({ showMenu }) =>
      !showMenu
        ? css`
            /* display: none; */
            left: -100vw;
          `
        : css`
            left: 0;
          `}

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 50%;
    position: fixed;
    top: 0;

    background: ${({ theme }) =>
      theme.isDarkMode ? `${colors.dark_main_gradient1}8c` : `${colors.light_main_gradient1}8c`};
    backdrop-filter: blur(6px);
    z-index: 2;
    transition: all 0.5s ease;
  }

  @media only screen and (min-width: 768px) /* For desktops : */ {
    position: unset;
    background: unset;
    z-index: unset;
    transition: unset;

    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
`

const StyledNav = styled.nav`
  @media only screen and (max-width: 768px) /* For mobile : */ {
    margin-top: 35px;
  }

  @media only screen and (min-width: 768px) /* For desktops : */ {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledList = styled.ul`
  list-style: none;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  color: ${colors.white};
  font-size: 1em;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  row-gap: 50px;
`

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: unset;
  border-radius: 20px;

  height: 45px;
  width: 70%;

  ${(props) =>
    props.active
      ? css`
          color: ${colors.white};
          background-color: ${(props) =>
            props.theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`};

          box-shadow: 0px 0px 10px -5px #000000;
        `
      : css`
          :hover {
            transition: all 0.5s ease;
            background-color: ${(props) =>
              props.theme.isDarkMode ? `${colors.dark_main}87` : `${colors.light_main_gradient1}87`};
            box-shadow: 0px 0px 10px -5px #000000;
          }
        `}

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Element = styled.li`
  /* margin-top: auto;
  margin-bottom: auto; */
  padding-left: 5px;
`
const MenuIcon = styled.img`
  width: 24px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg) brightness(102%) contrast(101%);
`
const ElementContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: flex-end;
  width: 100px;
`

const HeaderFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    height: 100%;
    margin: 20px;
    justify-content: space-between;
  }
`

export function Header() {
  const location = useLocation()

  const isCurrentRoute = (route) => {
    return location.pathname.startsWith(route)
  }

  const [showMenu, setShowMenu] = useState(false)
  const handleSHowMenu = () => {
    setShowMenu(!showMenu)
  }
  useEffect(() => {
    setShowMenu(false)
  }, [useLocation()])

  return (
    <StyledHeader>
      <BurgerMenu onClick={handleSHowMenu} showMenu={showMenu}></BurgerMenu>
      <Logo />
      <MenuContainer showMenu={showMenu}>
        <StyledNav>
          <StyledList>
            <StyledLink to="/films/" active={isCurrentRoute('/films') ? 1 : 0}>
              <ElementContainer>
                <MenuIcon src={filmIcon} alt="icone film" />
                <Element>Films</Element>
              </ElementContainer>
            </StyledLink>
            <StyledLink to="/series/" active={isCurrentRoute('/series') ? 1 : 0}>
              <ElementContainer>
                <MenuIcon src={serieIcon} alt="icone série" />
                <Element>Séries</Element>
              </ElementContainer>
            </StyledLink>
            <StyledLink to="/downloads/" active={isCurrentRoute('/downloads') ? 1 : 0}>
              <ElementContainer>
                <MenuIcon src={diversIcon} alt="icone boite" />
                <Element>Divers</Element>
              </ElementContainer>
            </StyledLink>
            <StyledLink to="/musiques/" active={isCurrentRoute('/musiques') ? 1 : 0}>
              <ElementContainer>
                <MenuIcon src={musicIcon} alt="icone musique" />
                <Element>Musiques</Element>
              </ElementContainer>
            </StyledLink>
            <StyledLink to="/speedtest" active={isCurrentRoute('/speedtest') ? 1 : 0}>
              <ElementContainer>
                <MenuIcon src={speedtestIcon} alt="icone speedtest" />
                <Element>Speedtest</Element>
              </ElementContainer>
            </StyledLink>
          </StyledList>
        </StyledNav>
        {/* <HeaderBorder></HeaderBorder> */}

        <HeaderFooter>
          <SpaceLeft />
          <ThemeToggle />
        </HeaderFooter>
      </MenuContainer>
    </StyledHeader>
  )
}
