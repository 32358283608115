var root = document.querySelector(':root')

const colors = {
  white: '#FFFFFF',
  black: '#000000',
  white_transparent: '#F7F7F766',
  light_grey_tone1: '#F7F7F7',
  light_grey_tone2: '#F3F3F3',
  light_grey_tone3: '#E3E3E3',
  // logo: '#272121',
  logo: '#FFFFFF',
  pourpre: '#984141',
  // main_background: '#E9EBEC',
  main_background: '#FFFFFF',

  light_main_gradient1: '#855EEB',
  light_main_gradient2: '#B390F1',
  light_main_gradient3: '#C3A2F2',
  light_main_gradient1_rgb: '133, 94, 235',
  light_main_gradient2_rgb: '179, 144, 241',
  light_main_gradient3_rgb: '195, 162, 242',

  dark_background: '#15202B',
  dark_main: '#709CFF',
  dark_secondary: '#2E416C',
  dark_main_gradient1: '#2E416C',
  dark_main_gradient2: '#2E416C',
  dark_main_gradient3: '#2E416C',
}

root.style.setProperty('--white_transparent-color', colors.white_transparent)
root.style.setProperty('--pourpre-color', colors.pourpre)
root.style.setProperty('--llight_grey_tone1-color', colors.light_grey_tone1)
root.style.setProperty('--light_grey_tone2-color', colors.light_grey_tone2)
root.style.setProperty('--light_grey_tone3-color', colors.light_grey_tone3)
root.style.setProperty('--main_background-color', colors.main_background)
root.style.setProperty('--logo-color', colors.logo)
root.style.setProperty('--pourpre-color', colors.pourpre)

root.style.setProperty('--light_main_gradient1-color', colors.light_main_gradient1)
root.style.setProperty('--light_main_gradient2-color', colors.light_main_gradient2)
root.style.setProperty('--light_main_gradient3-color', colors.light_main_gradient3)
root.style.setProperty('--light_main_gradient1_rgb-color', colors.light_main_gradient1_rgb)
root.style.setProperty('--light_main_gradient2_rgb-color', colors.light_main_gradient2_rgb)
root.style.setProperty('--light_main_gradient3_rgb-color', colors.light_main_gradient3_rgb)

export default colors
