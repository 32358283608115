import styled from 'styled-components'
import defaultPoster from '../../assets/images/default-poster.jpg'
import colors from '../../utils/colors'

const posterWidth = 150
const posterHeight = 225

const Loader = styled.div`
  position: absolute;
  overflow: hidden;
  border-radius: 25px;
  box-shadow: 0px 0px 4px 0px #000000;
  width: ${posterWidth}px;
  /* height: ${posterWidth / 2}px; */ //psoter horizontal
  height: ${posterHeight}px; //poster vertical

  :after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    top: 0;
    left: 0;
    background: linear-gradient(
      110deg,
      rgba(227, 227, 227, 0) 0%,
      rgba(227, 227, 227, 0) 40%,
      ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main_gradient1}30` : `${colors.light_main_gradient1}30`)} 50%,
      rgba(227, 227, 227, 0) 60%,
      rgba(227, 227, 227, 0) 100%
    );
    animation: gradient-animation_2 1.2s linear infinite;
  }

  @keyframes gradient-animation_2 {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
`

const Poster = styled.img`
  width: ${posterWidth}px;
  height: ${posterHeight}px; //poster vertical
  /* box-shadow: 0px 0px 4px 0px #000000; */

  object-fit: cover;
  border-radius: 25px;

  cursor: pointer;
`

export const LoadingPoster = ({ title }) => {
  return (
    <Loader>
      <Poster src={defaultPoster} alt={`poster du film ${title}`} />
    </Loader>
  )
}
