import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import searchIcon from '../../assets/icons/search.svg'
import colors from '../../utils/colors'
const levenshtein = require('js-levenshtein')

const StyledSearchBar = styled.div`
  /* position: fixed; */
  /* margin: auto; */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border: 2px solid
    ${(props) => (props.theme.isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.light_main_gradient1}`)};
  border-radius: 15px;
  background-color: ${colors.white_transparent};
  box-shadow: 0px 0px 12em 0.1em
    ${(props) => (props.theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`)};
  width: 300px;
  height: 2em;

  ${(props) =>
    props.theme.isDarkMode
      ? css`
          background-color: ${colors.dark_main};
        `
      : css`
          background: linear-gradient(60deg, ${colors.light_main_gradient2} 0%, ${colors.light_main_gradient3} 100%);
        `}
`
const SearchInput = styled.input`
  border-color: transparent;
  background-color: transparent;
  flex: 1;
  padding: 15px;
  font-size: 1.1em;
  color: ${colors.white};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${colors.white};
  }
`
const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  /* filter: invert(36%) sepia(9%) saturate(5396%) hue-rotate(320deg)
    brightness(78%) contrast(77%); */
`

export function SearchBar({ unfilteredMediaList, setMediaList }) {
  const [search, setSearch] = useState('')

  useEffect(() => {
    unfilteredMediaList && searchFor(search)
    // console.log('rawMediaList', unfilteredMediaList)
    // console.log('search.length', search.length)
  }, [search])

  function searchFor(search) {
    if (search && search.length > 0) {
      setMediaList(
        unfilteredMediaList &&
          unfilteredMediaList.filter((media) => {
            return media.filename.replaceAll('.', ' ').toLowerCase().includes(search.toLowerCase())
            // console.log(file.filename, levenshtein(file.filename.toLowerCase(), search.toLowerCase()))
          })
      )
    } else {
      // console.log('search.length = 0')
      setMediaList(unfilteredMediaList)
    }
  }

  return (
    <StyledSearchBar>
      <SearchIcon src={searchIcon} alt="" />
      <SearchInput
        type="search"
        onChange={(e) => setSearch(e.target.value)}
        name="search"
        id="searchbar"
        placeholder="Recherche..."
        value={search}
        // autoFocus
      />
    </StyledSearchBar>
  )
}
