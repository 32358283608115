import { createContext, useState } from 'react'

export const AuthTokenContext = createContext()

export const AuthProvider = ({ children }) => {
  const [authToken, setNewAuthToken] = useState(localStorage.getItem('authToken') || null)
  const setAuthToken = (token) => {
    setNewAuthToken(token)
    localStorage.setItem('authToken', token)
  }

  return <AuthTokenContext.Provider value={{ authToken, setAuthToken }}>{children}</AuthTokenContext.Provider>
}
