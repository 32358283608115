import { useContext } from 'react'
import { Bounce, ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import colors from '../../utils/colors'
import { ThemeContext } from '../../utils/useContext/ThemeContext'

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-theme--light {
    color: white;
    background: ${({ isDarkMode }) => (isDarkMode ? `${colors.dark_secondary}` : `${colors.light_main_gradient3}`)};
  }

  .Toastify__progress-bar {
    background: ${({ isDarkMode }) => (isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`)};
  }
`

export const PopupNotif = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <StyledToastContainer
      position="top-center"
      transition={Bounce}
      autoClose={2000}
      limit={3}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      isDarkMode={theme === 'dark'}
    />
  )
}
