import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import colors from '../../utils/colors'

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
const BreadcrumbContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  border-radius: 0.5rem;
  padding: 0.25rem;
  padding-inline: 0.5rem;

  ${({ theme }) =>
    theme.isDarkMode
      ? `background-color : ${colors.dark_main_gradient1};`
      : ` background: linear-gradient( 10deg, rgba(${colors.light_main_gradient2_rgb},0.3) 0%, rgba(${colors.light_main_gradient3_rgb},0.3) 50% );`}

  word-break: break-all;
`

const Breadcrumb = ({}) => {
  const currentPath = useLocation().pathname

  // separate the path by '/'
  const pathArray = currentPath.split('/')
  // remove all empty strings from the array
  pathArray.forEach((element, index) => {
    if (element === '') {
      pathArray.splice(index, 1)
    }
  })
  console.log('pathArray', pathArray)
  const breadcrumbs = useReactRouterBreadcrumbs(pathArray.map((path) => ({ path: `${path}/`, breadcrumb: `${path}` })))

  return (
    <BreadcrumbContainer>
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <BreadcrumbContent key={match.pathname}>
          <StyledLink to={`${match.pathname}/`}>{breadcrumb}</StyledLink>
          <span>/</span>
        </BreadcrumbContent>
      ))}
    </BreadcrumbContainer>
  )
}

export default Breadcrumb
