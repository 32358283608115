import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect } from 'react'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
`

export function CardLinkWrapper({ children, isDir, directoryTarget }) {
  useEffect(() => {
    // console.log('directoryTarget', directoryTarget)
  }, [])

  return (
    <>
      {isDir ? (
        <StyledLink to={directoryTarget}>{children}</StyledLink>
      ) : (
        // <StyledLink to={'films/' + directoryTarget}>{children}</StyledLink>
        <>{children}</>
      )}
    </>
  )
}
