import styled from 'styled-components'
import colors from '../../utils/colors'

const Tag = styled.div`
  font-family: Nuninto;
  font-size: 0.4em;
  color: white;
  background: ${(props) =>
    props.theme.isDarkMode
      ? `${colors.dark_main}`
      : `${colors.light_main_gradient1}`};
  border-radius: 25px;
  padding: 0.5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BetaTag = ({ text = 'Beta' }) => {
  return <Tag>{text}</Tag>
}
