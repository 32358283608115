import { useContext } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Login } from '../../components/Login'
import colors from '../colors'
import { AuthTokenContext } from '../useContext/AuthTokenContext'
import { ThemeContext } from '../useContext/ThemeContext'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) /* For desktops: */ {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* margin-left: 300px; */
    justify-content: flex-start;

    /* Main content encadré par le nav de couleur */
    /* background-color: ${colors.pourpre}; */
  }
`

export function GlobalContainer({ children }) {
  const { theme } = useContext(ThemeContext)

  return (
    // permet de propager le theme à tous les styled components
    <ThemeProvider theme={{ isDarkMode: theme === 'dark' }}>
      <Container>{children}</Container>
    </ThemeProvider>
  )
}

const MainContentContainer = styled.div`
  margin-top: 20px;
  height: calc(100vh - 40px);
  width: 100%;

  @media only screen and (max-width: 768px) {
    position: fixed;
    margin-top: 120px;
  }
`
export function MainContent({ children }) {
  const { authToken } = useContext(AuthTokenContext)

  if (!authToken) {
    return (
      <MainContentContainer>
        <Login />
      </MainContentContainer>
    )
  }

  return <MainContentContainer>{children}</MainContentContainer>
}
