import { useContext } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import colors from '../../utils/colors'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'
import { useEffect } from 'react'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`

const StyledH1 = styled.h1`
  font-size: 1.5em;
  text-align: center;
`

const StyledForm = styled.form`
  min-height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
`

const PasswordInput = styled.input`
  all: unset;
  border-radius: 0.5em;
  padding: 0.5em;
  border: 0.1em solid ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient3}`)};
  width: 100%;
`

const SubmitInput = styled.input`
  all: unset;

  color: ${colors.white};
  text-align: center;

  ${({ theme }) =>
    theme.isDarkMode
      ? `background-color : ${colors.dark_main_gradient1};`
      : ` background: linear-gradient( 10deg, ${colors.light_main_gradient2} 0%, ${colors.light_main_gradient3} 50% );`}

  border-radius: 0.5em;
  padding: 0.5em;
  border: 0.1em solid ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`)};
  cursor: pointer;
  width: 100%;

  :hover {
    box-shadow: inset 0px 0px 14px 3px rgba(0, 0, 0, 0.19);
  }

  :focus {
    box-shadow: inset 0px 0px 14px 3px rgb(0 0 0 / 19%);
    border: 0.2em solid
      ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.light_main_gradient1}`)};
  }
`

const Remember = styled.div`
  width: 100%;

  #remember {
    margin-right: 1em;
  }
`

export const Login = () => {
  const { setAuthToken } = useContext(AuthTokenContext)
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState(false)

  function handleSubmit(event) {
    event.preventDefault()
    loggin(password)
  }

  useEffect(() => {
    if (localStorage.getItem('password')) {
      setPassword(localStorage.getItem('password'))
    }
  }, [])

  async function loggin(password) {
    console.log('logging in...')

    let resp
    let data
    try {
      resp = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      })

      data = await resp.json()
      //   console.log(resp)
    } catch (error) {
      console.error(error)
    } finally {
      if (resp.ok) {
        setAuthToken(data.token)
        if (document.getElementById('remember').checked) {
          localStorage.setItem('password', password)
        }
      } else {
        setPasswordError(true)
        setPassword('')
      }
    }
  }

  return (
    <Container>
      <StyledH1>Pour accéder au contenu, veuillez vous connecter :</StyledH1>
      <StyledForm onSubmit={handleSubmit}>
        <label htmlFor="passwd">Mot de passe :</label>
        <PasswordInput
          type="password"
          name="passwd"
          id="passwd"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Remember>
          <input type="checkbox" name="remember" id="remember" checked />
          <label htmlFor="remember">Se souvenir de moi</label>
        </Remember>
        <SubmitInput type="submit" value="Accéder" />
        {passwordError && <>Mot de passe incorrect</>}
      </StyledForm>
    </Container>
  )
}
