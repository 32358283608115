import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { SearchBar } from '../../components/SearchBar'
import { TableList } from '../../components/TableList'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'
import { MainContent as MediaMainContent } from '../../pages/MediaListing'
import colors from '../../utils/colors'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { Error } from '../../components/Errors'

const MainContent = styled(MediaMainContent)`
  width: 85%;
  margin-top: 0;
  padding-top: 0;
`

export function FileListing({ type }) {
  const { authToken, setAuthToken } = useContext(AuthTokenContext)
  const { theme } = useContext(ThemeContext)
  const currentPath = useLocation().pathname
  // console.log('currentPath', currentPath)

  const [files, setFiles] = useState()
  const [unfilteredFileList, setUnfilteredFileList] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isExistingDir, setIsExistingDir] = useState(true)

  useEffect(() => {
    async function fetchFiles() {
      setIsLoading(true)
      setIsEmpty(false)
      setError(false)
      setIsExistingDir(true)

      // console.log('fetching...')
      let resp
      try {
        resp = await fetch(process.env.REACT_APP_API_URL + '/file-listing' + currentPath, {
          method: 'GET',
          headers: new Headers({
            'content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
          }),
        })

        // console.log(resp)
        if (!resp.ok) return

        const data = await resp.json()
        if (data.length < 1) setIsEmpty(true)
        setFiles(data)
        setUnfilteredFileList(data)
      } catch (error) {
        setError(true)
        console.error(error)
      } finally {
        setIsLoading(false)
        if (resp.status === 404) setIsExistingDir(false)
        if (resp.status === 401) setAuthToken(null)
      }
    }

    fetchFiles()
  }, [useLocation(), authToken])

  return (
    <MainContent>
      {files && <SearchBar unfilteredMediaList={unfilteredFileList && unfilteredFileList} setMediaList={setFiles} />}

      {isLoading && (
        <ReactLoading
          type={'bubbles'}
          color={theme === 'dark' ? `${colors.dark_main}` : `${colors.light_main_gradient1}`}
          height={'124px'}
          width={'124px'}
        />
      )}

      {!isExistingDir && <Error errorCode={404}></Error>}

      {error && <Error errorCode={500}></Error>}

      {!error && (
        <>
          {isEmpty && <>Dossier vide</>}
          {files && <TableList fileList={files} />}
        </>
      )}
    </MainContent>
  )
}
