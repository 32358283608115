export function formatCasting(casting) {
  if (!casting || casting.lenght < 1) {
    return null
  }

  let arrayOfActorRoleStrings = []

  for (let actor of casting) {
    const string = `${actor.name} (${actor.role})`
    arrayOfActorRoleStrings.push(string)
    console.log(string)
  }

  return arrayOfActorRoleStrings.join(', ') + ' ...'
}

export function torrentFileNameParser(filename) {
  const rx = /^(.+?)(S\d{2}|\[|\(\d{4}|\d{4}|\..{3}$|$)/gi
  const arr = rx.exec(filename)
  return arr[1].replaceAll('.', ' ')
}

export function formatSeasonEpisode(season, episode) {
  let S = ''
  let E = ''

  if (season) {
    S = season < 10 && !season.toString().startsWith('0') ? `S0${season}` : `S${season}`
  }
  if (episode) {
    E = episode < 10 && !episode.toString().startsWith('0') ? `E0${episode}` : `E${episode}`
  }

  return S + E
}

export function getFilenameWithoutExtension(filePath) {
  const fileName = filePath.split('/').pop() // Récupère le nom du fichier depuis le chemin
  const fileParts = fileName.split('.') // Sépare le nom du fichier par les points

  // Si le nom du fichier n'a pas de point, renvoyez le nom du fichier tel quel
  if (fileParts.length === 1) return fileName

  // Supprime la dernière partie (extension) et rejoint le reste
  fileParts.pop()
  return fileParts.join('.')
}
