import { useState, useEffect, useContext } from 'react'
// import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import infosIcon from '../../assets/icons/movie-card/iconoir/infos.svg'
import downloadIcon from '../../assets/icons/movie-card/iconoir/download.svg'
import copylinkIcon from '../../assets/icons/movie-card/iconoir/copylink.svg'

import { ActionIcon } from '../../components/VideoFileCard/ActionIcon'
import colors from '../../utils/colors'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileInfoPopup } from '../../components/VideoFileCard/FileInfoPopup'

import defaultPoster from '../../assets/images/default-poster.jpg'
import { MetaDatas } from '../../components/VideoFileCard/MetaData/MetaDatas'
import { RatingStar, RatingStarContainer } from 'rating-star'
import { StarIcon } from '../../components/VideoFileCard/StarIconComponent'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { useLocation } from 'react-router-dom'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'
import { Error } from '../../components/Errors'
import { toast } from 'react-toastify'
import { PopupNotif } from '../../components/PopupNotif/PopupNotif'

const PageContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;

  overflow-y: scroll;
`

const Cover = styled.div`
  width: 95%;
  height: 40%;
  /* min-height: 250px;
  height: 30%; */
  border-radius: 25px;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.backgroundCover}); */
  background: linear-gradient(
        90deg,
        ${(props) =>
          props.theme.isDarkMode
            ? css`
                ${colors.dark_main_gradient3}e3 50%
              `
            : css`
                ${colors.light_main_gradient2}e3
              `},
        rgba(0, 0, 0, 0.5)
      )
      50%,
    url(${(props) => props.backgroundCover && props.backgroundCover});
  background-size: cover;
  /* overflow: hidden; */
  color: ${(props) => (props.theme.isDarkMode ? `${colors.white}` : `${colors.black}`)};
`
const InformationContainer = styled.div`
  height: 100%;
  width: 100%;
  backdrop-filter: blur(6px);
  border-radius: 25px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track-piece:start {
    margin-top: 30px;
  }
  ::-webkit-scrollbar-track-piece:end {
    margin-bottom: 30px;
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: 10px;
  }

  ::-webkit-scrollbar-track {
    /* background: transparent; */
    scrollbar-width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: ${colors.light_main_gradient1}; */
    border-radius: 25px;
    box-shadow: inset 0 0 10px 10px
      ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`)};
    border: solid 3px transparent;
  }
`
const Informations = styled.div`
  height: 100%;
  /* width: 70%; */
  border-radius: 25px;
  padding: 25px;
  text-align: justify;

  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
`

const MediaLogo = styled.img`
  width: 20em;
  margin: auto;
`

const Title = styled.h1`
  margin-top: 0;
  font-size: 1.5em;
`
const Filename = styled.div`
  font-size: 0.8em;
`
const StyledRatingStar = styled.div`
  ${RatingStarContainer} {
    padding: 0px;
  }
  /* padding-top: 5px; */
  display: flex;
  flex-wrap: nowrap;
  /* width: 100%; */
  flex-direction: row;
`

const MetaDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  margin: 10px 0;
`

const Season = styled.div``

const Synopsis = styled.div`
  margin-top: 15px;
`
const Actors = styled.div`
  margin-top: 15px;
  padding-bottom: 20px;
  font-size: 0.75em;
`

const ActionMenu = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`

const Separator = styled.div`
  margin: 5px 0px;
  border-bottom: 1px solid ${(props) => (props.theme.isDarkMode ? 'white' : 'black')};
  width: 100%;
`

const StyledVideo = styled.video`
  ${(props) =>
    props.visible
      ? css`
          z-index: 5;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          margin: auto;
          width: 90%;
        `
      : css`
          width: 90%;
          max-width: 690px;
        `}
`
const VideoBackGround = styled.div`
  ${(props) =>
    props.visible
      ? css`
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: fixed;
          margin: auto;
          z-index: 4;
          background-color: #000000db;
          /* background: linear-gradient(
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 1)
            ),
            url(undefined); */
          background-size: cover;
          color: #000000;
          display: flex;
          justify-content: center;
        `
      : css`
          margin-top: 1%;
          width: 80%;
          max-width: 800px;
        `}
`

const CinemaModeButton = styled.button`
  padding: 10px 10px 10px 10px;
  border-radius: 25px;
  font-family: Nuninto;
  color: ${({ theme }) => (theme.isDarkMode ? `${colors.white}` : `${colors.black}`)};

  background-color: ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient2}`)};
  border: 0.1em solid ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient1}`)};
  :hover {
    box-shadow: 0px 0px 20px 0px
      ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main}6b` : `${colors.light_main_gradient1}6b`)};
    /* box-shadow: inset 0px 0px 14px 3px rgba(0, 0, 0, 0.19); */
  }

  :focus {
    box-shadow: inset 0px 0px 14px 3px rgb(0 0 0 / 19%);
    border: 0.2em solid
      ${({ theme }) => (theme.isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.light_main_gradient1}`)};
  }

  cursor: pointer;
`

export const Player = () => {
  document.title = 'Streambox - Player'

  const { theme } = useContext(ThemeContext)
  const { authToken, setAuthToken } = useContext(AuthTokenContext)

  const [isCinemaModeEnabled, setIsCinemaModeEnabled] = useState(false)

  const currentPath = useLocation().pathname
  // console.log('currentPath', currentPath)
  // console.log('useLocation()', useLocation())

  let isAudioMedia = false
  if (currentPath.startsWith('/play/musiques') || currentPath.startsWith('/play/downloads')) isAudioMedia = true

  const [file, setFile] = useState()
  const [noVideoFound, setnoVideoFound] = useState(false)
  useEffect(() => {
    async function fetchVideoInfos() {
      let resp
      try {
        setnoVideoFound(false)
        resp = await fetch(process.env.REACT_APP_API_URL + currentPath, {
          method: 'GET',
          headers: new Headers({
            'content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
          }),
        })

        // console.log('réponse :', resp)
        if (!resp.ok) {
          return
        }

        const data = await resp.json()
        // console.log(data)
        setFile({ ...data })
      } catch (error) {
        // console.error(error)
        setnoVideoFound(true)
      } finally {
        if (resp.status === 401) setAuthToken(null)
        if (resp.status === 404) setnoVideoFound(true)
      }
    }

    fetchVideoInfos()
  }, [useLocation(), authToken])

  const [showPopupInfo, setShowPopupInfo] = useState(false)

  useEffect(() => {
    function handleKeyPressedOnCinemaMode(e) {
      if (e.key === 'Escape') {
        setIsCinemaModeEnabled(false)
      }
    }
    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyPressedOnCinemaMode)
    }
  }, [])

  if (noVideoFound) {
    return <Error errorCode={404} />
  }

  return (
    <PageContainer>
      <PopupNotif />
      {!noVideoFound && file && (
        <>
          {!isAudioMedia && (
            <Cover backgroundCover={file.images && file.images.background ? file.images.background : null}>
              <InformationContainer>
                <Informations>
                  {file.images && file.images.logo ? (
                    <MediaLogo src={file.images.logo} alt="media logo" />
                  ) : (
                    <Title>{file.title}</Title>
                  )}
                  <Filename>{file.filename}</Filename>
                  <Separator></Separator>
                  <MetaDataContainer>
                    <Season>{file.seasonepisode}</Season>
                    <StyledRatingStar>
                      {file.note && (
                        <RatingStar
                          id="ranking-stars"
                          noBorder
                          starIcon={StarIcon}
                          colors={{ mask: theme === 'dark' ? `${colors.dark_main}` : `${colors.light_main_gradient1}` }}
                          rating={file.note}
                        />
                      )}
                    </StyledRatingStar>
                    <MetaDatas file={file} color={theme === 'dark' ? 'white' : 'black'} />
                  </MetaDataContainer>
                  <Separator></Separator>
                  <ActionMenu>
                    <ActionIcon
                      icon={infosIcon}
                      bubbleInfoText="Informations supplémentaires"
                      onClickAction={() => setShowPopupInfo(true)}
                      color={theme === 'dark' ? 'white' : 'black'}
                    />
                    <a style={{ display: 'inherit' }} href={file.directLink} download>
                      <ActionIcon
                        icon={downloadIcon}
                        bubbleInfoText="télécharger"
                        color={theme === 'dark' ? 'white' : 'black'}
                      />
                    </a>
                    <CopyToClipboard text={file.directLink}>
                      <div style={{ display: 'inherit' }}>
                        <ActionIcon
                          icon={copylinkIcon}
                          bubbleInfoText="copier le lien direct"
                          color={theme === 'dark' ? 'white' : 'black'}
                          onClickAction={() => {
                            toast('🔗 Lien direct copié !', {
                              // position: 'top-center',
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              theme: 'light',
                            })
                          }}
                        />
                      </div>
                    </CopyToClipboard>
                  </ActionMenu>
                  <Separator></Separator>
                  <Synopsis>{file.synopsis && file.synopsis}</Synopsis>
                  <Actors>{file.casting && `Avec : ${file.casting.join(', ')}`}</Actors>
                </Informations>
              </InformationContainer>
            </Cover>
          )}

          {file.directLink && (
            <>
              <VideoBackGround
                visible={isCinemaModeEnabled}
                onClick={() => setIsCinemaModeEnabled(false)}
              ></VideoBackGround>
              <StyledVideo
                width="80%"
                height="auto"
                controls
                poster={
                  file.images &&
                  (file.images.thumb ? file.images.thumb : file.images.background && file.images.background)
                }
                visible={isCinemaModeEnabled}
              >
                {isAudioMedia ? (
                  <>
                    <source src={file.directLink} type="audio/mp3" />
                    <source src={file.directLink} type="audio/wav" />
                    <source src={file.directLink} type="audio/m4a" />
                    Your browser does not support the video tag.
                  </>
                ) : (
                  <>
                    <source src={file.directLink} type="video/mp4" />
                    <source src={file.directLink} type="video/webm" />
                    <source src={file.directLink} type="video/ogg" />
                    <source src={file.directLink} type="video/x-msvideo" />
                    Your browser does not support the video tag.
                  </>
                )}
              </StyledVideo>
              {!isAudioMedia && (
                <CinemaModeButton onClick={() => setIsCinemaModeEnabled(!isCinemaModeEnabled)}>
                  Cinemamode
                </CinemaModeButton>
              )}
            </>
          )}

          {showPopupInfo && (
            <FileInfoPopup
              setVisible={setShowPopupInfo}
              poster={file && file.images.poster ? file.images.poster : defaultPoster}
              backgroundCover={file && file.images.background && file.images.background}
              file={file}
            />
          )}
        </>
      )}
    </PageContainer>
  )
}

//     'http://thaan.ovh/streambox/films/Shingeki.no.kyojin.S04/[Tsundere-Raws] Shingeki no Kyojin - The Final Season - 28 VOSTFR (CR) [WEB 1080p x264 AAC].mp4'
