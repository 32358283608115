import { useState, useContext } from 'react'
import { ReactComponent as MoonIcon } from '../../assets/icons/moon.svg'
import { ReactComponent as SunIcon } from '../../assets/icons/sun.svg'
import styled from 'styled-components'
import colors from '../../utils/colors'
import { ThemeContext } from '../../utils/useContext/ThemeContext'

const StyledLabel = styled.label`
  /* height: 100%;
  display: flex;
  align-content: center;
  align-items: flex-end;
  margin: 20px;
  justify-content: flex-end; */
`
const tooglerSize = 20
const ToogleWraper = styled.div`
  height: ${tooglerSize}px;
  width: ${tooglerSize * 2.5}px;
  background: ${({ isDarkMode }) => (isDarkMode ? `${colors.dark_main}` : `${colors.light_main_gradient3}`)};
  border-radius: 40px;
  padding: 6px;
  position: relative;
  transition: background 0.5s ease;
  cursor: pointer;

  ::before {
    content: '';
    display: block;
    margin-top: -2px;
    height: ${tooglerSize * 1.2}px;
    width: ${tooglerSize * 1.2}px;
    border-radius: 30px;
    background: ${({ isDarkMode }) =>
      isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.light_main_gradient1}`};
    position: absolute;
    z-index: 2;
    transform: translate(0);
    transition: transform 0.5s ease, background 0.5s ease;
  }

  ${(props) =>
    props.isEnabled &&
    `
    ::before {
    transform: translateX(${1.28 * tooglerSize}px);
    }

  `}
`

const ToogleTextHiden = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const ToggleIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 5px;

  svg {
    stroke: ${({ isDarkMode }) => (isDarkMode ? `${colors.dark_main_gradient1}` : `${colors.white}`)};
  }
`

const ToogleInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
`

const updateTheme = (isDarkEnabled) => {
  // Get CSS variables for background/foreground
  //   const styles = getComputedStyle(document.body)
  //   const black = styles.getPropertyValue('--black')
  //   const white = styles.getPropertyValue('--white')
  //   const docEl = document.documentElement

  if (isDarkEnabled) {
    // docEl.style.setProperty('--background', black)
    // docEl.style.setProperty('--foreground', white)
    // document.querySelector('html').classList.add('darkmode')
  } else {
    // docEl.style.setProperty('--background', white)
    // docEl.style.setProperty('--foreground', black)
    // document.querySelector('html').classList.remove('darkmode')
  }
}

export default function ThemeToggle() {
  const { theme, toggleTheme } = useContext(ThemeContext)
  const [isEnabled, setIsEnabled] = useState(true)

  /*
   * Read the blog post here:
   * https://letsbuildui.dev/articles/building-a-dark-mode-theme-toggle
   */

  // useEffect(() => {
  //   updateTheme(isEnabled)
  //   // toggleTheme()
  // }, [isEnabled])

  const toggleState = () => {
    setIsEnabled((prevState) => !prevState)
    toggleTheme()
  }

  return (
    <StyledLabel htmlFor="toggle">
      <ToogleWraper isEnabled={!isEnabled} isDarkMode={theme === 'dark'}>
        <ToogleTextHiden>{!isEnabled ? 'Enable Light Mode' : 'Enable Dark Mode'}</ToogleTextHiden>
        <ToggleIcons>
          <SunIcon />
          <MoonIcon />
        </ToggleIcons>
        <ToogleInput id="toggle" name="toggle" type="checkbox" checked={!isEnabled} onChange={toggleState} />
      </ToogleWraper>
    </StyledLabel>
  )
}
