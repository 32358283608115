import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'

import colors from '../../utils/colors'
import defaultPoster from '../../assets/images/default-poster.jpg'

import infosIcon from '../../assets/icons/movie-card/iconoir/infos.svg'
import downloadIcon from '../../assets/icons/movie-card/iconoir/download.svg'
import playIcon from '../../assets/icons/movie-card/iconoir/play.svg'
import copylinkIcon from '../../assets/icons/movie-card/iconoir/copylink.svg'
import { CardLinkWrapper } from './CardContainer'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileInfoPopup } from './FileInfoPopup'
import { ActionIcon } from './ActionIcon'
import { MetaDatas } from './MetaData/MetaDatas'
import { ThemeContext } from '../../utils/useContext/ThemeContext'
import { formatSeasonEpisode } from '../../utils/formatter'
import { AuthTokenContext } from '../../utils/useContext/AuthTokenContext'

import Axios from 'axios'
import { buildWebStorage, setupCache } from 'axios-cache-interceptor'
import { LoadingPoster } from './LoadingPoster'
const axios = setupCache(Axios, {
  storage: buildWebStorage(localStorage, 'streambox-mediainfos-cache:'),
  ttl: process.env.REACT_APP_API_TTL,
})

const cardWidth = 150

const FileCardOuterContainer = styled.div`
  width: ${cardWidth}px;
  height: ${cardWidth * 2}px; //poster vertical

  /* background-color: red; */

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.isDir &&
    `
    // justify-content: flex-start;
  `}
`

const Title = styled.div`
  color: ${(props) => (props.theme.isDarkMode ? 'white' : `${colors.light_main_gradient1}`)};
  /* font-size: 1.1em; */
  font-size: 100%;
  margin-top: 5px;

  //Autorise 2 lignes de texte et coupe avec "..."
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const posterWidth = 150
const posterHeight = 225

const FileContent = styled.div`
  width: ${posterWidth}px;
  /* height: ${posterWidth / 2}px; */ //psoter horizontal
  height: ${posterHeight}px; //poster vertical
  /* height: ${posterWidth * (1 + 2 / 3)}px; //poster vertical */
  position: relative;
`
const Poster = styled.img`
  width: ${posterWidth}px;
  /* height: ${posterWidth / 2}px; */ //psoter horizontal
  height: ${posterHeight}px; //poster vertical
  /* height: ${posterWidth * (1 + 2 / 3)}px; //poster vertical */
  box-shadow: 0px 0px 4px 0px #000000;

  object-fit: cover;
  border-radius: 25px;

  cursor: pointer;
`

const SeasonEpisodeBanner = styled.div`
  color: ${colors.white};
  background-color: ${
    ({ theme }) => (theme.isDarkMode ? `${colors.dark_main_gradient1}` /*80*/ : `${colors.light_main_gradient1}`) /*80*/
  };

  /* backdrop-filter: blur(10px); */
  position: absolute;
  height: 8%;
  /* width: 20%; */
  right: 15%;
  top: 0;
  bottom: 0;
  font-size: 0.8em;
  border-radius: 0 0 10px 10px;
  padding: 4px;
`

const MetaDataCard = styled.div`
  backdrop-filter: blur(15px);
  background-color: #e3dbef57;
  color: unset;

  position: absolute;

  box-shadow: 0px 0px 10px -7px #000000;
  height: 70%;
  /* width: 80%; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border-radius: 0 25px 25px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-content: space-evenly;

  width: 0%;
  opacity: 0%;

  ${FileContent}:hover & {
    width: 80%;
    opacity: 100%;
  }

  transition: all 0.5s ease;

  cursor: pointer;
`

const ActionMenu = styled.div`
  width: ${posterWidth}px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
`
const InlineLink = styled.a`
  display: inherit;
`

const handleLinkCopied = (file) => {
  toast('🔗 Lien direct copié !', {
    // position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
  })
}

export function VideoFileCard({ file }) {
  const { theme } = useContext(ThemeContext)
  const { authToken, setAuthToken } = useContext(AuthTokenContext)
  const [showPopupInfo, setShowPopupInfo] = useState(false)
  // const currentPath = useLocation().pathname

  const [mediaInfosLoading, setMediaInfosLoading] = useState(true)
  const [mediaInformations, setMediaInformations] = useState()
  // const [mediaInfosError, setMediaInfosError] = useState(false)

  useEffect(() => {
    async function fetchMediaInformations() {
      setMediaInfosLoading(true)
      const url = `/media-infos/${file.type}/${file.filename}${file.baseDir}`

      let resp
      try {
        resp = await axios.get(process.env.REACT_APP_API_URL + url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: 'application/json',
          },
        })
        // console.log('req axios : ', resp.cached)
      } catch (error) {
        // console.error(error)
        if (error.response.status === 401) setAuthToken(null)
      } finally {
        if (resp.status && resp.status === 200) {
          const data = resp.data
          let seasonepisode = null
          if (data.season) {
            seasonepisode = formatSeasonEpisode(data.season, data.episode)
          }
          data.baseDir = file.baseDir
          data.seasonepisode = seasonepisode
          data.title = !data.title || data.title === '' ? file.title : data.title
          // console.log(data)
          setMediaInfosLoading(false)
          setMediaInformations(data)
        }
      }
    }

    fetchMediaInformations()
  }, [])

  return (
    <FileCardOuterContainer isDir={file.isDir}>
      <CardLinkWrapper isDir={file.isDir} directoryTarget={file.filename + '/'}>
        <CopyToClipboard text={file.directLink}>
          <FileContent onClick={() => !file.isDir && handleLinkCopied(file)}>
            {mediaInfosLoading ? (
              <LoadingPoster title={file.title} />
            ) : (
              <Poster
                src={
                  mediaInformations && mediaInformations.poster
                    ? mediaInformations.poster
                    : mediaInformations && mediaInformations.images && mediaInformations.images.poster
                    ? mediaInformations.images.poster
                    : defaultPoster
                }
                alt={`poster du film ${file.title}`}
              />
            )}

            {mediaInformations && mediaInformations.seasonepisode && (
              <SeasonEpisodeBanner>{mediaInformations.seasonepisode}</SeasonEpisodeBanner>
            )}

            <MetaDataCard>
              <MetaDatas file={mediaInformations} color="black" />
            </MetaDataCard>
          </FileContent>
        </CopyToClipboard>
        <Title>
          {file.title} {!file.title && file.filename} {mediaInformations && mediaInformations.seasonepisode}
        </Title>
      </CardLinkWrapper>
      <ActionMenu>
        <ActionIcon
          icon={infosIcon}
          bubbleInfoText="Informations supplémentaires"
          onClickAction={() => setShowPopupInfo(true)}
          color={theme === 'dark' ? 'white' : 'black'}
        />
        {!file.isDir && (
          <>
            <InlineLink href={file.directLink} download>
              <ActionIcon
                icon={downloadIcon}
                bubbleInfoText="télécharger"
                color={theme === 'dark' ? 'white' : 'black'}
              />
            </InlineLink>
            <ActionIcon
              icon={playIcon}
              bubbleInfoText="lire"
              link={`/play${file.baseDir + file.filename}`}
              color={theme === 'dark' ? 'white' : 'black'}
            />
            <CopyToClipboard text={file.directLink}>
              <div style={{ display: 'inherit' }}>
                <ActionIcon
                  icon={copylinkIcon}
                  bubbleInfoText="copier le lien direct"
                  onClickAction={() => handleLinkCopied(file)}
                  color={theme === 'dark' ? 'white' : 'black'}
                />
              </div>
            </CopyToClipboard>
          </>
        )}
      </ActionMenu>
      {showPopupInfo && (
        <FileInfoPopup
          setVisible={setShowPopupInfo}
          poster={
            mediaInformations && mediaInformations.poster
              ? mediaInformations.poster
              : mediaInformations && mediaInformations.images && mediaInformations.images.poster
              ? mediaInformations.images.poster
              : defaultPoster
          }
          backgroundCover={
            mediaInformations && mediaInformations.images.background && mediaInformations.images.background
          }
          file={mediaInformations}
        />
      )}
    </FileCardOuterContainer>
  )
}
