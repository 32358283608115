import { useContext } from 'react'
import { createGlobalStyle, ThemeProvider, useTheme } from 'styled-components'

import Daggersquare from '../assets/fonts/daggersquare.otf'
import Nunito from '../assets/fonts/Nunito-VariableFont_wght.ttf'

import colors from './colors'
import { ThemeContext } from './useContext/ThemeContext'

const GlobalStyleCSS = createGlobalStyle`
@font-face {
    font-family: dagger;
    src: url(${Daggersquare});
}
@font-face {
    font-family: Nuninto;
    src: url(${Nunito});
}

    body{
        margin: auto;
        font-family: Nuninto;
        /* background-color: ${({ isDarkMode }) => (isDarkMode ? `${colors.dark_background}` : `none`)}; */
        background-repeat: no-repeat;
        /* background: linear-gradient(130deg,rgba(255,188,188,1) 0%,rgba(255,219,168,1) 50%,rgba(254,252,251,1) 100%) fixed; */
        /* overflow: auto; */

        background-color: ${(props) => (props.theme.isDarkMode ? `${colors.dark_background}` : `unset`)};
        color: ${(props) => (props.theme.isDarkMode ? `${colors.white}` : `black`)};

        transition: background 0.5s ease;
    }

    /* .blur {
        background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
        backdrop-filter: blur(100px); // This be the blur
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index:-1;
    } */
`
export function GlobalStyle() {
  const { theme } = useContext(ThemeContext)
  //   const theme = useTheme()
  return (
    <ThemeProvider theme={{ isDarkMode: theme === 'dark' }}>
      <GlobalStyleCSS />
    </ThemeProvider>
  )
}
