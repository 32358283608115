import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../utils/colors'

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  border-radius: 8px;
  /* :hover {
  background-color: ${colors.light_grey_tone3};
} */
  cursor: pointer;

  ${({ color }) =>
    color === 'white'
      ? `filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg)
    brightness(102%) contrast(101%);`
      : null};
`

const StyledLink = styled(Link)`
  display: inherit;
`

export function LinkWraper({ link, children }) {
  if (link) {
    return <StyledLink to={link}>{children}</StyledLink>
  } else {
    return <>{children}</>
  }
}

export function ActionIcon({
  icon,
  bubbleInfoText,
  onClickAction,
  color,
  link,
}) {
  return (
    <LinkWraper link={link}>
      <Icon
        src={icon}
        title={bubbleInfoText}
        onClick={onClickAction}
        color={color}
      />
    </LinkWraper>
  )
}
