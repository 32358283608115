import styled from 'styled-components'
import colors from '../../../utils/colors'

const MetaDataWraper = styled.div`
  font-size: 0.7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 3px; */
  /* background-color: ${colors.light_grey_tone3}; */
  /* border: solid 1px; */
  border-radius: 10px;
  /* margin: 2px; */
  text-align: center;

  /* height: 10px; */

  color: ${({ color }) => (color === 'white' ? `${colors.white}` : `${colors.black}`)};
`

const MetaDataIcon = styled.img`
  /* margin-right: 5px; */
  width: 16px;
  height: 16px;

  ${({ color }) =>
    color === 'white' &&
    `
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(175deg)
      brightness(102%) contrast(101%);
    
  `}; /* filter: invert(36%) sepia(9%) saturate(5396%) hue-rotate(320deg)
    brightness(78%) contrast(77%); */
  /* filter: invert(5%) sepia(9%) saturate(1670%) hue-rotate(315deg)
    brightness(92%) contrast(81%); */
`

export function MetaData({ icon, text, color }) {
  return (
    <MetaDataWraper color={color}>
      <MetaDataIcon src={icon} color={color} /> {text}
    </MetaDataWraper>
  )
}
