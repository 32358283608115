import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../utils/colors'
import { BetaTag } from '../BetaTag'

const StyledLogo = styled.h1`
  @media only screen and (max-width: 768px) {
    margin: unset;
  }

  font-family: dagger;
  font-size: 2em;
  margin: auto;
  text-align: center;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoPartOne = styled.span`
  /* color: ${colors.white}; */
  color: ${colors.logo};
`
const LogoPartTwo = styled.span`
  color: ${colors.logo};
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

export function Logo() {
  return (
    <StyledLogo>
      <StyledLink to="/films/">
        <LogoPartOne>Stream</LogoPartOne>
        <LogoPartTwo>Box</LogoPartTwo>
      </StyledLink>
      <BetaTag text={'Beta v3'} />
    </StyledLogo>
  )
}
