// import React, { useEffect, useRef } from 'react'
// import videojs from 'video.js'
// import 'video.js/dist/video-js.css'

// const VideoPlayer = ({ src }) => {
//   const videoNode = useRef(null)
//   const player = useRef(null)
//   console.log('src', src)

//   useEffect(() => {
//     player.current = videojs(videoNode.current, { fluid: true }, () => {
//       player.current.src({
//         src: src,
//         type: 'video/mp4',
//       })
//     })

//     return () => {
//       if (player.current) {
//         player.current.dispose()
//       }
//     }
//   }, [src])

//   return (
//     <div>
//       <video ref={videoNode} className="video-js" playsInline />
//     </div>
//   )
// }

// export default VideoPlayer

// import React, { useRef, useEffect } from 'react'
// import Hls from 'hls.js'
// import { getFilenameWithoutExtension } from '../../utils/formatter'

// const StreamingPlayer = ({ src }) => {
//   const videoRef = useRef(null)

//   //  get filename without extension of the src
//   const filename = getFilenameWithoutExtension(src)
//   console.log('src', src)
//   console.log('filename', filename)

//   return (
//     <video id="my-video" class="video-js" controls preload="auto" width="640" height="264" data-setup="{}">
//       <source src={src} type="video/mp4" />
//       <p class="vjs-no-js">
//         To view this video please enable JavaScript, and consider upgrading to a web browser that
//         <a href="https://videojs.com/html5-video-support/" target="_blank">
//           supports HTML5 video
//         </a>
//       </p>
//     </video>
//   )
// }

// export default StreamingPlayer

// PLYR
import React, { useRef, useEffect } from 'react'
import Plyr from 'plyr-react'
import 'plyr-react/plyr.css'
import { getFilenameWithoutExtension } from '../../utils/formatter'

const StreamingPlayer = ({ src }) => {
  const videoRef = useRef(null)

  //  get filename without extension of the src
  const filename = getFilenameWithoutExtension(src)
  console.log('src', src)
  console.log('filename', filename)

  const videoOptions = {
    type: 'video',
    sources: [
      {
        src: src,
        // type: 'video/mp4',
        type: 'application/x-mpegURL',
        size: 720,
      },
    ],
    // Ajoutez ici d'autres options Plyr si nécessaire.
  }

  return (
    <div>
      <Plyr source={videoOptions} />
    </div>
  )
}

export default StreamingPlayer
