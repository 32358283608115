import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from './components/Header'
import { Home } from './pages/Home'
import { MediaListingPage } from './pages/MediaListing'
import { GlobalStyle } from './utils/GlobalStyle'
import { GlobalContainer, MainContent } from './utils/GlobalContainer'
import { FileListing } from './pages/FileListing/FileListing'

import { Player } from './pages/Player'
import { ThemeProvider } from './utils/useContext/ThemeContext'
import { AuthProvider } from './utils/useContext/AuthTokenContext'
import { Error } from './components/Errors'
import { Speedtest } from './pages/Speedtest'
import { Stream } from './pages/Stream'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider>
        <GlobalStyle />
        <GlobalContainer>
          {/* Contient de ThemeProvider de styled-component */}
          <Header />
          <MainContent>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/films/:directory/*" element={<MediaListingPage type="films" />} />
              <Route path="/films/" element={<MediaListingPage type="films" />} />

              <Route path="/series/:directory/*" element={<MediaListingPage type="series" />} />
              <Route path="/series/" element={<MediaListingPage type="series" />} />

              <Route path="/downloads/:directory/*" element={<FileListing type="divers" />} />
              <Route path="/downloads/" element={<FileListing type="divers" />} />
              <Route path="/musiques/:directory/*" element={<FileListing type="musiques" />} />
              <Route path="/musiques/" element={<FileListing type="musiques" />} />

              <Route path="/play/:directory/*" element={<Player />} />
              <Route path="/play/" element={<Player />} />
              <Route path="/stream/:directory/*" element={<Stream />} />

              <Route path="/speedtest/" element={<Speedtest />} />

              <Route path="/*" element={<Error errorCode={404} />} />
            </Routes>
          </MainContent>
        </GlobalContainer>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
