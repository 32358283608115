import apkIcon from '../assets/icons/file-list/apk.png'
import archiveIcon from '../assets/icons/file-list/archive.png'
import audioIcon from '../assets/icons/file-list/audio.png'
import backIcon from '../assets/icons/file-list/back.png'
import exeIcon from '../assets/icons/file-list/exe.png'
import folderIcon from '../assets/icons/file-list/folder.png'
import nfo from '../assets/icons/file-list/nfo.png'
import pdfIcon from '../assets/icons/file-list/pdf.png'
import rarIcon from '../assets/icons/file-list/rar.png'
import videoIcon from '../assets/icons/file-list/video.png'
import cdIcon from '../assets/icons/file-list/cd.png'

export const fileIcons = {
    default: nfo,
    folder: folderIcon,
    back: backIcon,
    '.apk': apkIcon,
    '.pdf': pdfIcon,
    '.iso': cdIcon,

    '.7z': archiveIcon,
    '.arj': archiveIcon,
    '.deb': archiveIcon,
    '.pkg': archiveIcon,
    '.rar': archiveIcon,
    '.rpm': archiveIcon,
    '.tar.gz': archiveIcon,
    '.z': archiveIcon,
    '.zip': archiveIcon,

    '.aif': audioIcon,
    '.cda': audioIcon,
    '.mid': audioIcon,
    '.mp3': audioIcon,
    '.mpa': audioIcon,
    '.ogg': audioIcon,
    '.wav': audioIcon,
    '.wma': audioIcon,
    '.wpl': audioIcon,
    '.m4a': audioIcon,

    '.bat': exeIcon,
    '.bin': exeIcon,
    '.cgi': exeIcon,
    '.exe': exeIcon,
    '.jar': exeIcon,
    '.msi': exeIcon,

    '.ai': '',
    '.bmp': '',
    '.gif': '',
    '.ico': '',
    '.jpeg': '',
    '.png': '',
    '.ps': '',
    '.psd': '',
    '.svg': '',
    '.tif': '',
    '.webp': '',

    '.3g2': videoIcon,
    '.3gp': videoIcon,
    '.avi': videoIcon,
    '.flv': videoIcon,
    '.h264': videoIcon,
    '.m4v': videoIcon,
    '.mkv': videoIcon,
    '.mov': videoIcon,
    '.mp4': videoIcon,
    '.mpg': videoIcon,
    '.mpeg': videoIcon,
    '.rm': videoIcon,
    '.swf': videoIcon,
    '.vob': videoIcon,
    '.webm': videoIcon,
    '.wmv': videoIcon,

    '.doc': nfo,
    '.docx': nfo,
    '.odt': nfo,
    '.rtf': nfo,
    '.tex': nfo,
    '.txt': nfo,
    '.wpd': nfo,
}

export const mediaExtensions = [
    '.3g2',
    '.3gp',
    '.avi',
    '.flv',
    '.h264',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpg',
    '.mpeg',
    '.rm',
    '.swf',
    '.vob',
    '.webm',
    '.wmv',
    '.aif',
    '.cda',
    '.mid',
    '.mp3',
    '.mpa',
    '.ogg',
    '.wav',
    '.wma',
    '.wpl',
    '.m4a',
]